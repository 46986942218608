var Draggabilly = require('draggabilly');

// turn draggables off
// var draggableElems = document.querySelectorAll('.draggable');
var draggableElems = []
// array of Draggabillies
var draggies = []


// init Draggabillies
for ( var i=0, len = draggableElems.length; i < len; i++ ) {
  var draggableElem = draggableElems[i];

  initSticker(draggableElem);

}

function initSticker(el) {

  var index = el.getAttribute('data-index');
  var position = JSON.parse(sessionStorage.getItem('sticker-'+index));

  var draggie = new Draggabilly(el);
  draggie.on( 'dragEnd', listener );

   if (position) {
    el.style.left=position.x+'px';
    el.style.top=position.y+'px';
    el.style.right = 'auto';

  }

  draggie.on( 'staticClick', function(e) {
    e.preventDefault();

    var href = this.element.getAttribute('data-href');
    var target = this.element.getAttribute('data-target');

    if (target == '_blank') {
      window.open(href);
    } else {
      window.location = href;
    }

  });

}


function listener(event, pointer) {
  var index = this.element.getAttribute('data-index');
  sessionStorage.setItem('sticker-'+index,JSON.stringify(this.position));
}
